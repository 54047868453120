import { createRouter, createWebHistory } from 'vue-router'
import { constantRoutes } from './constantRoutes'
import store from '@/vuex/store'
import { getToken } from '@/utils/auth'
import NProgress from 'nprogress'

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes  // 初始化时只加载constantRoutes
})

// 记录动态路由是否已添加，防止重复添加
let dynamicRoutesAdded = false

// 不需要登录就可以访问的白名单
const whiteList = ['/login', '/mp/login', '/mp/register', '/callback', '/data-screen']

// 重置路由
export function resetRouter() {
  // 清除所有路由
  const newRouter = createRouter({
    history: createWebHistory(),
    routes: constantRoutes
  })
  
  // 替换路由实例的matcher
  router.matcher = newRouter.matcher
  
  // 重置动态路由标志
  dynamicRoutesAdded = false
}

// 添加动态路由的统一方法，确保路由不重复
async function addDynamicRoutes() {
  // 如果已经添加过，则不再重复添加
  if (dynamicRoutesAdded) return []
  
  // 根据角色生成路由
  const accessRoutes = await store.dispatch('permission/generateRoutes', store.getters.roles)
  
  // 添加路由
  accessRoutes.forEach(route => {
    if (!router.hasRoute(route.name)) {
      router.addRoute(route)
    }
  })
  
  // 标记已添加
  dynamicRoutesAdded = true
  
  return accessRoutes
}

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  
  // 调试日志
  if (process.env.NODE_ENV === 'development') {
    console.log('路由守卫:', {
      to: to.path,
      from: from.path,
      hasToken: getToken(),
      roles: store.getters.roles,
      dynamicRoutesAdded
    })
  }

  const hasToken = getToken()

  // 白名单直接通过
  if (whiteList.includes(to.path)) {
    next()
    return
  }

  // 没有token，重定向到登录页
  if (!hasToken) {
    next(`/login`)
    NProgress.done()
    return
  }
  
  // 如果是访问登录页，直接跳转到首页
  if (to.path === '/login' || to.path === '/mp/login') {
    next({ path: '/admin' })
    NProgress.done()
    return
  }

  // 判断是否已获取用户角色
  const hasRoles = store.getters.roles && store.getters.roles.length > 0
  
  if (process.env.NODE_ENV === 'development') {
    console.log('用户角色状态:', {
      hasRoles,
      roles: store.getters.roles,
      dynamicRoutesAdded
    })
  }
  
  // 处理刷新页面的情况，此时dynamicRoutesAdded为false，但用户角色信息可能存在
  if (hasRoles && !dynamicRoutesAdded) {
    // 添加动态路由
    await addDynamicRoutes()
    
    // 如果当前路由未匹配，则重新导航
    if (to.matched.length === 0) {
      next({ ...to, replace: true })
      return
    }
    
    // 路由已匹配，可以正常导航
    next()
    return
  }
  
  // 有角色且动态路由已添加，但路由未匹配，可能是直接访问了未注册的路由
  if (hasRoles && dynamicRoutesAdded && to.matched.length === 0) {
    if (process.env.NODE_ENV === 'development') {
      console.log('路由已添加但未匹配，可能访问了不存在的路由')
    }
    // 这里可以导航到404页面或首页
    next('/404')
    NProgress.done()
    return
  }
  
  // 有角色且路由已匹配，正常导航
  if (hasRoles) {
    next()
    return
  }

  // 没有角色信息，先获取用户信息
  try {
    // 获取用户信息
    await store.dispatch('user/getInfo')
    
    // 添加动态路由
    await addDynamicRoutes()

    // 路由添加后重定向，确保能正确匹配路由
    next({ ...to, replace: true })
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error('获取用户信息失败:', error)
    }
    // 失败则重置token并重定向到登录页
    await store.dispatch('user/resetToken')
    resetRouter()
    next(`/login`)
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router