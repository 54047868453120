import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "modal-content"
};
const _hoisted_2 = {
  style: {
    "margin": "15px auto"
  }
};
import { reactive, ref, watch, computed } from 'vue';
// 组件文档：https://cropper.chengpeiquan.com/zh/quick-start.html
import VuePictureCropper, { cropper } from 'vue-picture-cropper';
import { PhotoCompress } from "@/utils/compressUtil";
export default {
  __name: 'wk-imgCropper',
  props: {
    visible: {
      // 控制组件是否可见
      type: Boolean
    },
    image: {
      // 传入文件或链接
      type: [String, File]
    },
    presetMode: {
      type: Object
    },
    options: {
      type: Object
    },
    boxStyle: {
      type: Object
    },
    showClear: {
      // 是否显示 Clear 按钮
      type: Boolean
    },
    showReset: {
      // 是否显示 Reset 按钮
      type: Boolean
    },
    isCompress: {
      // 是否压缩裁剪后的图片大小
      type: Boolean
    },
    compressSize: {
      // 压缩图片大小限制
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        maxSize: 0.3 * 1024 * 1024,
        // 300k
        minSize: 0.03 * 1024 * 1024 // 30k
      }
    }
  },
  emits: ["close"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;

    /** 裁剪组件属性配置 */
    const corpOptions = computed(() => {
      return {
        viewMode: 1,
        // 裁剪框范围 1-只能在图片区域内活动
        dragMode: 'move',
        // 可选值 crop(可绘制裁剪框) | move(仅移动)
        aspectRatio: 1 / 1,
        // 裁剪框的宽高比
        cropBoxResizable: true,
        // 是否可以调整裁剪区的大小
        ...props.options
      };
    });
    /** 裁剪区域的样式 */
    const corpBoxStyle = computed(() => {
      return {
        width: 'auto',
        height: 'auto',
        backgroundColor: '#f8f8f8',
        margin: 'auto',
        ...props.boxStyle
      };
    });

    /** 预设模式配置 */
    const corpPresetMode = computed(() => {
      return {
        ...props.presetMode
      };
    });
    const emits = __emit;
    watch(() => props.visible, val => {
      isShowModal.value = val;
      if (!val) {
        pic.value = '';
        result.dataURL = '';
        result.blobURL = '';
        reset();
      }
    });
    watch(() => props.image, val => {
      pic.value = val;
    });
    const isShowModal = ref(false);
    const pic = ref('');
    const result = reactive({
      dataURL: '',
      blobURL: ''
    });

    /**
     * Get cropping results
     */
    async function getResult() {
      if (!cropper) return;
      const base64 = cropper.getDataURL();
      const blob = await cropper.getBlob();
      if (!blob) return;
      let file = await cropper.getFile();
      result.dataURL = base64;
      result.blobURL = URL.createObjectURL(blob);
      const {
        minSize,
        maxSize
      } = props.compressSize;

      // 是否限制文件大小
      if (props.isCompress && file.size > maxSize) {
        console.log("compress");
        const photoCompress = new PhotoCompress(minSize, maxSize);
        photoCompress.compress(file, function (f) {
          const r = new FileReader();
          r.readAsDataURL(f);
          r.onload = function (e) {
            emits("close", {
              result,
              file: f
            });
          };
        });
      } else {
        console.log("crop", file.size);
        emits("close", {
          result,
          file
        });
      }
    }

    /**
     * Clear the crop box
     */
    function clear() {
      if (!cropper) return;
      cropper.clear();
    }

    /**
     * Reset the default cropping area
     */
    function reset() {
      if (!cropper) return;
      cropper.reset();
    }

    /**
     * The ready event passed to Cropper.js
     */
    function ready() {
      // console.log('Cropper is ready.')
    }
    function cancel() {
      emits("close");
    }
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        style: {
          "text-align": "center"
        },
        modelValue: isShowModal.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => isShowModal.value = $event),
        title: "图片裁剪",
        width: "50%",
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        onClose: cancel,
        "align-center": ""
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_unref(VuePictureCropper), {
          img: pic.value,
          onReady: ready,
          boxStyle: corpBoxStyle.value,
          options: corpOptions.value,
          presetMode: corpPresetMode.value
        }, null, 8, ["img", "boxStyle", "options", "presetMode"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          class: "default-btn",
          onClick: cancel
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("取消")])),
          _: 1
        }), __props.showClear ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          class: "default-btn",
          onClick: clear
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("清除")])),
          _: 1
        })) : _createCommentVNode("", true), __props.showReset ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          class: "default-btn",
          onClick: reset
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("重置")])),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_button, {
          class: "default-btn primary",
          onClick: getResult
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("确认")])),
          _: 1
        })])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};