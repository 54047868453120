import request from '@/utils/request'
import axios from "axios";

export function getAliyunPolicy(params) {
    return request({
        url: '/oss/generate-sign',
        params,
        method: "GET"
    })
}

export function getStsToken(){
    return request({
        url: '/oss/get-sts-token',
        method: "GET"
    })
}

export function uploadFileToOss (data, action){
    return axios({
        url: action,
        data: data,
        method: "POST",
        headers: {"Content-Type": "multipart/form-data"},
    })
}

export function doCallBack() {
    return request({
        url: '/oss/callback',
        method: "POST"
    })
}
