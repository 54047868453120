/*
* 基于axios封装的请求模块
* */
import axios from 'axios'
// 在非组件成员中想要使用router,则需要导入router模块
import router from '../router'
// 非组件模块可以这样加载使用element-ui中的message消息提示组件
import {ElNotification} from 'element-plus'
import {clearAuthData, getToken, getUserId} from "@/utils/auth";


const BASE_URL =
    process.env.NODE_ENV === 'development'
        ? '/api/'
        : 'https://hxy.zzch.cn/api'
// 创建一个axios实例,说白了就是复制了一个axios
// 我们通过这个实例去发请求，把需要的配置配置给这个实例
const request = axios.create({
    baseURL: BASE_URL, // 请求的基础路径
    timeout: 10000
})

// axios 基础设置
request.defaults.withCredentials = true
request.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * 请求拦截器
 */
request.interceptors.request.use(function (config) {
    if(getToken() && getUserId()){ //判断storage中是否存储token，如果存储了则加入到请求头中。
        config.headers['token'] = getToken();
        config.headers['user-id'] = getUserId();
    }
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
})

/**
 * 响应拦截器
 */
request.interceptors.response.use(function (response) {
    if(response.data.code === 403){
        ElNotification({
            title: 'Warning',
            message: '登录状态无效，请重新登录',
            type: 'warning',
        })
        // 清除登陆信息
        clearAuthData()
        // 跳转到登录页面
        router.push('/login')
    }

    return response


}, function (error) {
    // 任何响应码 超出 2xx 的响应都会进入这里
    // 对响应错误做点什么
    if (error.status === undefined) {
        if (error.code === "ECONNABORTED"){
            ElNotification({
                title: 'Error',
                message: '网络好像有点慢，请稍后再重试。',
                type: 'error',
            })
            return Promise.reject(error)
        }
    }

    const status = error.response.status
    if (status === 401) {
        // 清除本地存储中的user数据
        window.localStorage.removeItem('user')
        // 跳转到登录页面
        router.push('/login')
        ElNotification({
            title: 'Warning',
            message: '登录状态无效，请重新登录',
            type: 'warning',
        })
    } else if (status === 403) {
        // 没有操作权限， token未携带或已过期
        ElNotification({
            title: 'Warning',
            message: '没有操作权限， token未携带或已过期!',
            type: 'warning',
        })
        router.push('/mp/login')

    } else if (status === 400) {
        // 客户端参数错误
        ElNotification({
            title: 'Warning',
            message: '客户端请求参数错误,请检查请求参数！',
            type: 'warning',
        })
    } else if (status >= 500) {
        // 服务端错误
        ElNotification({
            title: 'Error',
            message: '服务端内部异常，请稍后重试！',
            type: 'error',
        })
    }
    return Promise.reject(error)
})



// 导出请求模块
export default request

