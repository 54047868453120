import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_2 = {
  style: {
    "margin-left": "5px"
  }
};
const _hoisted_3 = {
  style: {
    "margin-left": "5px"
  }
};
const _hoisted_4 = {
  style: {
    "margin-left": "5px"
  }
};
const _hoisted_5 = {
  style: {
    "margin-left": "5px"
  }
};
const _hoisted_6 = {
  style: {
    "height": "calc(100vh - 60px)"
  }
};
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';
import { Expand, Fold } from '@element-plus/icons-vue';
export default {
  __name: 'SideBar',
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    const isCollapse = ref(false);

    // 获取菜单数据
    const menus = computed(() => {
      return store.state.permission.menuItems || [];
    });
    const clickToCollapse = () => {
      isCollapse.value = !isCollapse.value;
    };
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, {
        height: "calc(100vh - 60px)"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          collapse: isCollapse.value,
          class: "el-menu-vertical-demo",
          "default-active": _unref(router).currentRoute.value.path,
          router: true
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menus.value, menu => {
            return _openBlock(), _createElementBlock(_Fragment, {
              key: menu.path
            }, [menu.children && menu.children.length > 0 ? (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 0,
              index: '/admin/' + menu.path
            }, {
              title: _withCtx(() => [_createVNode(_unref(Icon), {
                width: "22",
                color: menu.meta?.color,
                icon: menu.meta?.icon
              }, null, 8, ["color", "icon"]), _createElementVNode("span", _hoisted_2, _toDisplayString(menu.meta?.title), 1)]),
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.children, child => {
                return _openBlock(), _createElementBlock(_Fragment, {
                  key: child.path
                }, [child.children && child.children.length > 0 ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                  key: 0,
                  index: `/admin/${menu.path}/${child.path}`
                }, {
                  title: _withCtx(() => [_createVNode(_unref(Icon), {
                    width: "18",
                    color: child.meta?.color,
                    icon: child.meta?.icon
                  }, null, 8, ["color", "icon"]), _createElementVNode("span", _hoisted_3, _toDisplayString(child.meta?.title), 1)]),
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(child.children, subChild => {
                    return _openBlock(), _createBlock(_component_el_menu_item, {
                      key: subChild.path,
                      index: `/admin/${menu.path}/${child.path}/${subChild.path}`
                    }, {
                      default: _withCtx(() => [_createVNode(_unref(Icon), {
                        width: "18",
                        color: subChild.meta?.color,
                        icon: subChild.meta?.icon
                      }, null, 8, ["color", "icon"]), _createElementVNode("span", _hoisted_4, _toDisplayString(subChild.meta?.title), 1)]),
                      _: 2
                    }, 1032, ["index"]);
                  }), 128))]),
                  _: 2
                }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: 1,
                  index: `/admin/${menu.path}/${child.path}`
                }, {
                  default: _withCtx(() => [_createVNode(_unref(Icon), {
                    width: "18",
                    color: child.meta?.color,
                    icon: child.meta?.icon
                  }, null, 8, ["color", "icon"]), _createElementVNode("span", _hoisted_5, _toDisplayString(child.meta?.title), 1)]),
                  _: 2
                }, 1032, ["index"]))], 64);
              }), 128))]),
              _: 2
            }, 1032, ["index"])) : _createCommentVNode("", true)], 64);
          }), 128))]),
          _: 1
        }, 8, ["collapse", "default-active"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
        onClick: clickToCollapse,
        plain: "",
        class: "foldButton"
      }, {
        icon: _withCtx(() => [!isCollapse.value ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0
        }, {
          default: _withCtx(() => [_createVNode(_unref(Fold))]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_el_icon, {
          key: 1
        }, {
          default: _withCtx(() => [_createVNode(_unref(Expand))]),
          _: 1
        }))]),
        _: 1
      })])]);
    };
  }
};