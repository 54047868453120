import request from '@/utils/request'
import qs from "qs";


/**
 * 用户登出
 *
 */

export function logout() {
    return request({
        url: '/user/logout',
        method: 'DELETE'
    })
}

export function login(data) {
    return request({
        url: '/user/login',
        method: 'POST',
        data: data
    })
}

export function register(params){

    return request({
        url:`/user/register`,
        data:params,
        method:"POST",
    })
}

export function registerBatch(data){

    return request({
        url:`/user/batch/register`,
        data,
        method:"POST",
    })
}

export function getInfo(){
    return request({
        url:'/user/info',
        method:"GET"
    })
}

export function hasPhoneNumber(phoneNumber){
    return request({
        url:'/user/check-phone',
        params:{phoneNumber},
        method:"GET"
    })
}

export function deleteUser(arr){
    const encodedRoles = arr.join('&ids=');
    return request({
        url:`/user/delete?ids=${encodedRoles}`,
        method:"DELETE",
    })
}

export function setUserAvatar(params){

    return request({
        url:`/user/profile/avatar`,
        params,
        method:"POST",
    })
}

function setUserName(params){

    return request({
        url:`/user/profile/userName`,
        params,
        method:"POST",
    })
}


function getUserList(params){
    return request({
        url:`/user/list`,
        params,
        method:"GET",
    })
}




export function bindOpenId(params){
    return request({
        url:`/user/bind`,
        params,
        method:"get",
    })
}

function getPermissions(id){
    return request({
        url:`/user/permissions`,
        params:{
            id
        },
        method:"GET",
    })
}

/**
 * 获取当前用户角色列表
 * @param id
 * @return {*}
 */
function getRoles(id){
    return request({
        url:`/user/roles`,
        params:{id},
        method:"GET",
    })
}

function getUsersRoles(ids){
    return request({
        url:`/user/users-roles`,
        params:{ids},
        method:"GET",
        paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
        },
    })
}

export const user_api = {
    hasPhoneNumber,setUserName,getUserList,getPermissions,getRoles,getUsersRoles,
    /**
     * 获取公开用户列表信息
     * @returns
     */
    publicList: (params)=>request({
        url:`/user/public-list`,
        params,
        method:"GET",
    }),
    /**
     * 按条件获取用户信息
     * @param params
     * @return {*}
     */
    getUserListConditions: (params)=> request({
        url:`/user/list-conditions`,
        params,
        method:"GET",
    }),
    /**
     * 更新用户
     * @param data
     * @return {*}
     */
    updateUser: (data)=>request({
        url:`/user/update`,
        data,
        method:"PUT",
    })
}