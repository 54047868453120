import request from '@/utils/request'
import qs from "qs";

export function getRoles(params) {
    return request({
        url: '/role',
        method: 'GET',
        params:params,
    })
}

export function addOrUpdateRole(data) {
    return request({
        url: '/role',
        method: 'POST',
        data,
        headers: {"Content-Type":"application/x-www-form-urlencoded"}
    })
}


export function deleteRole(id) {

    return request({
        url: `/role`,
        method: 'DELETE',
        params:{id},

    })

}

export function deleteRoleBatch(ids) {
    const encodedIds = ids.join('&ids=');
    return request({
        url: `/role/batch?ids=${encodedIds}`,
        method: 'DELETE',
    })

}



export function getRolesName(ids) {
    return request({
        url: `/role/name`,
        params:{ids},
        paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
        },
        method: 'GET',

    })

}