import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "view_container"
};
const _hoisted_2 = {
  class: "block"
};
const _hoisted_3 = {
  style: {
    "text-align": "center",
    "margin-top": "10px"
  }
};
import { h, computed, reactive, ref } from "vue";
import store from "@/vuex/store";
import { doCallBack, getAliyunPolicy, uploadFileToOss } from "@/api/oss";
import axios from "axios";
import { ElDivider, ElMessage, genFileId } from "element-plus";
import { getUserId } from "@/utils/auth";
import { formatTime, formatYear } from "@/utils/tools";
import WkImgCropper from "@/components/wk-imgCropper/wk-imgCropper.vue";
export default {
  __name: 'PersonalCenter',
  setup(__props) {
    const userInfo = computed(() => store.getters.userInfo);
    const userAvatar = computed(() => store.getters.avatar); // 全局变量头像
    const avatarTemp = ref(userInfo.value.avatar); // 临时头像变量

    const handleRemove = (uploadFile, uploadFiles) => {
      console.log("处理移除", uploadFile, uploadFiles);
    };
    const objData = reactive({
      success_action_status: '200',
      OSSAccessKeyId: '',
      policy: '',
      signature: '',
      key: '',
      host: '',
      dir: ''
    });

    // 过滤文件格式
    function extractExtension(filename) {
      // 获取最后一个点的索引
      const index = filename.lastIndexOf('.');
      // 如果存在点，且点不是在第一个位置
      if (index !== -1 && index !== 0) {
        // 返回从点到字符串末尾的部分
        return filename.slice(index);
      }
      // 否则返回空字符串
      return '';
    }

    // 获取oss上传授权信息
    const getOssPolicy = rawFile => {
      // 上传限制
      if (rawFile.type !== 'image/jpeg') {
        ElMessage.error('头像图像必须为 JPG/JPEG 格式的图片');
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('上传的JPG图像不能超过2MB!');
        return false;
      }
      return new Promise((resolve, reject) => {
        getAliyunPolicy({
          dir: 'user-avatar/'
        }).then(res => {
          const {
            data
          } = res.data;
          objData.OSSAccessKeyId = data.accessid;
          objData.policy = data.policy;
          objData.signature = data.signature;
          objData.key = data.dir + "${filename}";
          objData.host = data.host;
          objData.dir = data.dir;
          objData.fileName = 'avatar-' + getUserId() + extractExtension(rawFile.name);
          resolve(true); // 继续上传
        }).catch(err => {
          console.log(err);
          reject(false); // 停止上传
        });
      });
    };
    const selfUpload = () => {
      if (!saveFile.value) {
        console.log("上传失败");
        return;
      }
      console.log("自动上传");
      const formData = new FormData();
      formData.append("OSSAccessKeyId", objData.OSSAccessKeyId);
      formData.append("policy", objData.policy);
      formData.append("signature", objData.signature);
      formData.append("host", objData.host);
      formData.append("dir", objData.dir);
      formData.append("key", objData.dir + "${filename}");
      formData.append("success_action_status", "200");

      // 修改文件名
      let rawFile = saveFile.value;
      let f = new File([rawFile], objData.fileName, {
        type: rawFile.type
      });
      f.uid = rawFile.uid;
      rawFile = f;

      // 提交文件
      formData.append("file", rawFile);

      // 开始上传
      uploadFileToOss(formData, objData.host).then(res => {
        console.log('上传成功回调，', res);
        if (res.status > 200) ElMessage.error("头像更新失败请稍后再试");
        const url = objData.host + '/' + objData.key.replace("${filename}", objData.fileName);
        store.dispatch("user/setAvatar", url); // 更新用户数据库头像
        ElMessage.success("头像更新成功！");
      }).catch(() => {});
    };

    // 上传成功回调函数
    // 上传成功后的钩子
    const uploadSuccess = () => {
      ElMessage({
        message: '上传成功',
        type: 'success'
      });
    };
    // 上传失败后的钩子
    const uploadError = rej => {
      ElMessage({
        message: '上传失败',
        type: 'error'
      });
    };

    // 覆盖前一个文件
    const handleExceed = files => {
      fileList.value.pop();
      fileList.value.push(files);
    };
    const getFormData = () => {
      const formData = new FormData();
      formData.append("OSSAccessKeyId", objData.OSSAccessKeyId);
      formData.append("policy", objData.policy);
      formData.append("signature", objData.signature);
      formData.append("host", objData.host);
      formData.append("dir", objData.dir);
      formData.append("key", objData.dir);
      return formData;
    };
    const form = reactive({
      name: '',
      region: '',
      date1: '',
      date2: '',
      delivery: false,
      type: [],
      resource: '',
      desc: ''
    });
    const onSubmit = () => {
      console.log('submit!');
    };

    // 头像预览
    const isSaveAvatar = ref(false);

    // 图片裁剪
    let saveFile = ref({});
    let selectPic = ref("");
    let isShowCorpper = ref(false);
    function hideCorpper(data) {
      isShowCorpper.value = false;
      if (data) {
        // 回调处理后的文件 dataURL为临时预览地址
        const {
          result: {
            dataURL
          },
          file
        } = data;
        avatarTemp.value = dataURL;
        saveFile.value = file;
        isSaveAvatar.value = true;
      }
    }
    /** 保存并上传裁剪后的文件 */
    function saveForm() {
      selfUpload(); //上传
      isSaveAvatar.value = false;
    }

    /** 选择文件 */
    function beforeUpload(file) {
      getOssPolicy(file).then(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          selectPic.value = String(reader.result);
          isShowCorpper.value = true;
        };
      }).catch(() => {
        return false;
      });
      return false;
    }
    return (_ctx, _cache) => {
      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
      const _component_el_avatar = _resolveComponent("el-avatar");
      const _component_el_space = _resolveComponent("el-space");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_descriptions = _resolveComponent("el-descriptions");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_descriptions, {
        title: "个人信息",
        direction: "horizontal",
        column: 1,
        size: "large",
        border: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
          "label-align": "right",
          label: "用户名"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(userInfo.value.userName), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          "label-align": "right",
          label: "手机号码"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(userInfo.value.phoneNumber), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          "label-align": "right",
          label: "头像",
          span: 2
        }, {
          default: _withCtx(() => [_createVNode(_component_el_space, {
            direction: "horizontal"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_space, {
              size: "large"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_avatar, {
                shape: "square",
                size: 80,
                src: userAvatar.value
              }, null, 8, ["src"])]),
              _: 1
            })]), _createVNode(_component_el_upload, {
              action: objData.host,
              "on-remove": handleRemove,
              "before-upload": beforeUpload,
              "list-type": "picture"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                size: "small"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("更改")])),
                _: 1
              })]),
              _: 1
            }, 8, ["action"]), _createVNode(_component_el_dialog, {
              style: {
                "text-align": "center"
              },
              modelValue: isSaveAvatar.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => isSaveAvatar.value = $event),
              title: "头像预览"
            }, {
              footer: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
                onClick: _cache[0] || (_cache[0] = $event => isSaveAvatar.value = false)
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("取消")])),
                _: 1
              }), _createVNode(_component_el_button, {
                type: "primary",
                onClick: saveForm
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("确认更改")])),
                _: 1
              })])]),
              default: _withCtx(() => [_createVNode(_component_el_space, null, {
                default: _withCtx(() => [_createVNode(_component_el_space, {
                  direction: "vertical"
                }, {
                  default: _withCtx(() => [_cache[3] || (_cache[3] = _createElementVNode("span", null, "旧头像", -1)), _createVNode(_component_el_avatar, {
                    shape: "square",
                    size: 120,
                    src: userAvatar.value
                  }, null, 8, ["src"])]),
                  _: 1
                }), _createVNode(_unref(ElDivider), {
                  direction: "vertical"
                }), _createVNode(_component_el_space, {
                  direction: "vertical"
                }, {
                  default: _withCtx(() => [_cache[4] || (_cache[4] = _createElementVNode("span", null, "新头像", -1)), _createVNode(_component_el_avatar, {
                    shape: "square",
                    size: 120,
                    src: avatarTemp.value
                  }, null, 8, ["src"])]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"]), _createVNode(WkImgCropper, {
              visible: _unref(isShowCorpper),
              image: _unref(selectPic),
              onClose: hideCorpper,
              isCompress: true
            }, null, 8, ["visible", "image"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          "label-align": "right",
          label: "注册日期"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(formatTime)(userInfo.value.createTime)), 1)]),
          _: 1
        })]),
        _: 1
      })])]);
    };
  }
};