import {createStore} from 'vuex'
import getters from './getters'

// import request from "@/axios/request";


// 使用require.context()实现前端工程化引入文件
const modulesFiles = require.context('./modules',true,/\.js$/)

const modules = modulesFiles.keys().reduce((modules,modulePath)=>{
    // set './user.js' => 'user'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
},{})


// 创建一个新的 store 实例
export default createStore({
    modules,
    getters
})
