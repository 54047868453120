const TokenKey = 'token'
const UserIdKey = 'user-id'

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

export function getUserId() {
    return localStorage.getItem(UserIdKey)
}

export function setUserId(userId) {
    return localStorage.setItem(UserIdKey, userId)
}

export function removeUserId() {
    return localStorage.removeItem(UserIdKey)
}

// 清除所有认证相关的数据
export function clearAuthData() {
    removeToken()
    removeUserId()
}