import {getInfo, login, setUserAvatar, user_api} from "@/api/user";
import {Promise} from "es6-promise";
import {reactive} from "vue";
import store from "@/vuex/store";
import { getToken, setToken,setUserId,getUserId, removeToken, clearAuthData } from '@/utils/auth'
import { getRoles } from "@/api/role";



/**
 * 默认state，方便用于初始化。
 */
const getDefaultState = ()=>{
    return reactive({
        userInfo:{},
        name: '',
        empId: '',
        avatar: '',
        roles:[],
        permissions:[],
        introduction: ''
    })
}

const state = getDefaultState()

const mutations = {
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_USERNAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        // 头像读取附带数据，强制刷新缓存
        avatar = avatar + "?timestamp=" + new Date().getTime(); // 设置时间戳，强制刷新缓存。
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_EMP_ID: (state, empId) => {
        state.empId = empId
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions
    },
    CLEAR_STATE: ()=>{
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_INFO: (state, info) => {
        state.info = info
    }
}

const actions = {
    // user login
    login({commit}, userAccount) {
        return new Promise((resolve, reject) => {
            login(userAccount).then(response => {
                if (response.status === 200 && response.data.code === 200) {
                    const { data } = response.data
                    // 存储 token 和 userId
                    commit('SET_TOKEN', data.token)
                    setToken(data.token)
                    setUserId(data.userId)
                    
                    // 初始化用户信息
                    commit('SET_USERINFO', data)
                    if (data.userName) {
                        commit('SET_USERNAME', data.userName)
                    }
                    if (data.avatar) {
                        commit('SET_AVATAR', data.avatar)
                    }
                    resolve(response)
                } else {
                    reject(response.data.msg || '登录失败')
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    clearState({commit}){
        commit('CLEAR_STATE')
    },

    // get user info
    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo().then(async response => {
                try {
                    const { data } = response

                    if (!data) {
                        reject('验证失败，请再次尝试登录以获取登录信息！')
                    }

                    let { userName, avatar, empId, id } = data.data

                    commit('SET_USERINFO', data.data) // 存储用户个人信息

                    if(!avatar) {
                        avatar = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                    }

                    // 获取角色映射
                    const rolesResponse = await getRoles()
                    const roles = rolesResponse.data.data
                    // 等待获取角色信息完成
                    try {
                        if (data.data.roles.length > 0) {
                            commit('SET_ROLES', data.data.roles.map(roleId => roles.find(role => role.id === roleId)))
                        } else {
                            commit('SET_ROLES', roles.filter(role => role.id === 2))
                        }

                        // 获取用户权限
                        const permResponse = await user_api.getPermissions(id)
                        commit('SET_PERMISSIONS', permResponse.data.data)

                        commit('SET_USERNAME', userName)
                        commit('SET_AVATAR', avatar)
                        commit('SET_EMP_ID', empId)

                        // 如果登陆了就初始化数据
                        await store.dispatch('employee/formatDepJobs')
                        await store.dispatch('classes/getOptions')

                        resolve(data)
                    } catch (error) {
                        console.error('获取用户角色或权限失败:', error)
                        reject(error)
                    }
                } catch (error) {
                    reject(error)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 设置头像
    setAvatar({ commit }, avatar) {
        return new Promise((resolve,reject) => {
            setUserAvatar({
                userId:getUserId(),
                avatarUrl:avatar
            }).then(res => {

                commit('SET_AVATAR', avatar)
                resolve(res)
            }).catch(error => {
                console.log("头像设置失败",error)
                reject(error)
            })
        })
    },

    // 用户登出
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                commit('CLEAR_STATE')
                clearAuthData()
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },

    // 重置 Token
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('CLEAR_STATE')
            clearAuthData()
            resolve()
        })
    },

    // 清除状态
    clearState({ commit }) {
        commit('CLEAR_STATE')
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}