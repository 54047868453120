import Layout from '@/layout/LayoutIndex.vue'
import { color } from 'echarts'

// 异步路由表 - 需要根据角色动态加载的路由
export const asyncRoutes = [
  {
    path: '/admin',
    component: Layout,
    redirect: '/admin/portal/home',
    children: [
      // 门户组 (group_id: 1)
      {
        path: 'portal',
        name: 'Portal',
        meta: {
          title: '门户',
          icon: 'flat-color-icons:home',
          group_id: 1,
          priority: 1,
        },
        children: [
          {
            path: 'home',
            name: 'Home',
            component: () => import('@/views/HomePage.vue'),
            meta: { 
              title: '首页',
              icon: 'flat-color-icons:home',
              priority: 1
            }
          },
          {
            path: 'about',
            name: 'About',
            component: () => import('@/views/AboutPage.vue'),
            meta: {
              title: '关于我们',
              icon: 'flat-color-icons:about',
              priority: 2
            }
          },
        ]
      },

      // 学生管理组 (group_id: 2)
      {
        path: 'student',
        name: 'StudentManage',
        meta: {
          title: '学生管理',
          icon: 'flat-color-icons:reading',
          group_id: 2,
          priority: 2
        },
        children: [
          {
            path: 'list',
            name: 'StudentList',
            component: () => import('@/views/student/StudentListPage.vue'),
            meta: {
              title: '学生列表',
              icon: 'flat-color-icons:list',
              roles: ['admin','moral_admin', 'moral_user']
            }
          },
          {
            path: 'classes',
            name: 'ClassesManage',
            component: () => import('@/views/student/ClassesManagement.vue'),
            meta: {
              title: '班级管理',
              icon: 'flat-color-icons:department',
              roles: ['admin', 'teacher', 'moral_admin', 'moral_user']
            }
          },
          {
            path: 'mine-classes',
            name: 'MineClassesList',
            component: () => import('@/views/student/MineClassesList.vue'),
            meta: {
              title: '我的班级',
              icon: 'flat-color-icons:reading',
              permissions: ['menu:mine_classes:list']
            }
          },
          {
            path: 'exam',
            name: 'ExamManagement',
            component: () => import('@/views/student/ExamManagement.vue'),
            meta: {
              title: '考试管理',
              icon: 'flat-color-icons:document',
              roles: ['admin']
            }
          }
        ]
      },

      // 用户管理组 (group_id: 3)
      {
        path: 'user',
        name: 'UserManage',
        meta: {
          title: '用户管理',
          icon: 'flat-color-icons:conference-call',
          group_id: 3,
          priority: 6
        },
        children: [
          {
            path: 'list',
            name: 'UserList',
            component: () => import('@/views/user/UserListPage.vue'),
            meta: {
              title: '用户列表',
              icon: 'flat-color-icons:list',
              roles: ['admin']
            }
          },
          {
            path: 'role',
            name: 'RoleManage',
            component: () => import('@/views/user/RoleManagePage.vue'),
            meta: {
              title: '角色管理',
              icon: 'flat-color-icons:businessman',
              roles: ['admin']
            }
          },
          {
            path: 'perm',
            name: 'PermManage',
            component: () => import('@/views/user/PermManagePage.vue'),
            meta: {
              title: '权限管理',
              icon: 'flat-color-icons:key',
              roles: ['admin']
            }
          }
        ]
      },

      // 教职管理组 (group_id: 4)
      {
        path: 'employee',
        name: 'EmployeeManage',
        meta: {
          title: '教职管理',
          icon: 'flat-color-icons:manager',
          group_id: 4,
          priority: 3
        },
        children: [
          {
            path: 'list',
            name: 'EmployeeList',
            component: () => import('@/views/employee/EmployeeManagement.vue'),
            meta: {
              title: '员工管理',
              icon: 'flat-color-icons:manager',
              roles: ['admin']
            }
          }
        ]
      },

      // 站点管理组 (group_id: 5)
      {
        path: 'site',
        name: 'SiteManage',
        meta: {
          title: '站点管理',
          icon: 'flat-color-icons:flow-chart',
          group_id: 5,
          priority: 7
        },
        children: [
          {
            path: 'alert-user',
            name: 'AlertUser',
            component: () => import('@/views/site/AlertUser.vue'),
            meta: {
              title: '提醒用户',
              icon: 'flat-color-icons:idea',
              roles: ['admin']
            }
          },
          {
            path: 'swiper',
            name: 'HomeSwiperManage',
            component: () => import('@/views/site/HomeSwiperManage.vue'),
            meta: {
              title: '轮播图',
              icon: 'flat-color-icons:picture',
              roles: ['admin']
            }
          },
          {
            path: 'medals',
            name: 'SchoolMedals',
            component: () => import('@/views/site/SchoolMedals.vue'),
            meta: {
              title: '学校奖牌',
              icon: 'flat-color-icons:diploma-1',
              roles: ['admin']
            }
          },
          {
            path: 'websiteUpdateLog',
            name: 'WebsiteUpdateLog',
            component: () => import('@/views/site/WebsiteUpdateLog.vue'),
            meta: {
              title: '更新日志',
              icon: 'flat-color-icons:document',
              roles: ['admin']
            }
          }
        ]
      },

      // 公众号管理 (group_id: 6)
      {
        path: 'wechat',
        name: 'WechatManage',
        meta: {
          title: '公众号',
          icon: 'fa:wechat',
          group_id: 6,
          color: "#2ecc71",
          priority: 5
        },
        children: [
          {
            path: 'template-massage',
            name: 'TemplateMassageManage',
            component: () => import('@/views/wechat/TemplateMassageManage.vue'),
            meta: {
              title: '模板消息',
              color: "#2ecc71",
              icon: 'fa:wechat',
              roles: ['admin']
            }
          }
        ]
      },

      // 德育管理 (group_id: 7)
      {
        path: 'moral_edu',
        name: 'MoralEduManage',
        meta: {
          title: '德育管理',
          icon: 'flat-color-icons:collaboration',
          group_id: 7,
          priority: 4
        },
        children: [
          {
            path: 'list',
            name: 'MoralEduManagement',
            component: () => import('@/views/moral_edu/MoralEduManagement.vue'),
            meta: {
              title: '德育列表',
              icon: 'flat-color-icons:survey',
              roles: ['admin', 'moral_admin', 'moral_user']
            }
          },
          {
            path: 'mentor',
            name: 'MentorManage',
            component: () => import('@/views/moral_edu/MentorManage.vue'),
            meta: {
              title: '导师管理',
              icon: 'flat-color-icons:voice-presentation',
              permissions: ['menu:mentor:list'],
              roles: ['admin']
            }
          },
          {
            path: 'mentor_data',
              name: 'MentorDataDashboard',
              component: () => import('@/views/moral_edu/MentorDataDashboard.vue'),
              meta: {
                title: '数据统计',
                icon: 'flat-color-icons:combo-chart',
                roles: ['admin']
            }
          }
        ]
      },

      // 爱心银行 (group_id: 8)
      {
        path: 'charity',
        name: 'CharityManage',
        meta: {
          title: '长鸿基金会',
          icon: 'mdi:charity',
          color: "#e74c3c",
          group_id: 8,
          priority: 3
        },
        children: [
          {
            path: 'points',
            name: 'CharityPoints',
            component: () => import('@/views/charity/CharityPointsManage.vue'),
            meta: {
              title: '积分管理',
              icon: 'flat-color-icons:calculator',
              roles: ['admin', 'charity_admin']
            }
          }
        ]
      },

      // 审批管理 (group_id: 9)
      {
        path: 'approval',
        name: 'ApprovalManage',
        meta: {
          title: '审批管理',
          icon: 'flat-color-icons:approval',
          color: '#3498db',
          group_id: 9,
          priority: 2,
          roles: ['admin', 'approval_admin']
        },
        children: [
          {
            path: 'my',
            name: 'MyApproval',
            component: () => import('@/views/approval/MyApproval.vue'),
            meta: {
              title: '我的审批',
              icon: 'flat-color-icons:todo-list',
              roles: ['*']
            }
          },
          {
            path: 'pending',
            name: 'PendingApproval',
            component: () => import('@/views/approval/PendingApproval.vue'),
            meta: {
              title: '待我审批',
              icon: 'flat-color-icons:inspection',
              roles: ['*']
            }
          },
          {
            path: 'cc',
            name: 'CCApproval',
            component: () => import('@/views/approval/CCApproval.vue'),
            meta: {
              title: '抄送我的',
              icon: 'flat-color-icons:news',
              roles: ['*']
            }
          },
          {
            path: 'template',
            name: 'ApprovalTemplate',
            component: () => import('@/views/approval/ApprovalTemplate.vue'),
            meta: {
              title: '审批模板',
              icon: 'flat-color-icons:template',
              roles: ['admin', 'approval_admin']
            }
          },
          {
            path: 'process',
            name: 'ProcessManage',
            component: () => import('@/views/approval/ProcessManage.vue'),
            meta: {
              title: '流程管理',
              icon: 'flat-color-icons:flow-chart',
              roles: ['admin', 'approval_admin']
            }
          }
        ]
      }
    ]
  }
] 