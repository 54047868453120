import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import store from "@/vuex/store";
import { computed } from "vue";

/**
 * 为传递数组数据解析请求参数
 * @param list
 * @returns {*}
 */
export const encodeList = list => {
  return list.join('&ids=');
};

/**
 * 对OSS图片进行缩放
 * @param path
 * @param h - 高度
 * @param m - 缩放模式 默认：lfit
 * @return {*}
 */
export const getResizeOssImgPath = (path, h, m) => {
  if (path == null || path.indexOf("aliyuncs.com") === -1) return path; // 判断是否为oss链接，否则返回原地址
  if (!m) m = 'lfit';
  let url = path;
  if (path.indexOf("?") === -1) {
    url += `?x-oss-process=image/resize,h_${h},m_${m}`;
  } else {
    url += `&x-oss-process=image/resize,h_${h},m_${m}`;
  }
  return url;
};
const formatDateNoDay = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear(); // 获取四位数的年份
  const month = date.getMonth() + 1; // 获取月份（0-11），需要加1
  return year + "年" + month + "月"; // 拼接成指定格式的日期
};
export const formatYear = dateString => {
  const date = new Date(dateString);
  return date.getFullYear();
};
export const formatTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!time) return '-';
  try {
    const date = new Date(time);
    if (isNaN(date.getTime())) return '-';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return format.replace('YYYY', year).replace('MM', month).replace('DD', day).replace('HH', hours).replace('mm', minutes).replace('ss', seconds);
  } catch (error) {
    console.warn('Time format error:', error);
    return '-';
  }
};
const getClassNameById = id => {
  const classList = computed(() => store.getters.classes);
  const result = classList.value.find(clazz => clazz.id === id);
  return result ? result.className : null;
};
const getClassIdByName = value => {
  const classList = computed(() => store.getters.classes);
  const result = classList.value.find(clazz => clazz.className === value);
  return result ? result.id : null;
};
const getJobIdByName = value => {
  const classList = computed(() => store.getters.jobs);
  const result = classList.value.find(job => job.name === value);
  return result ? result.id : null;
};
const getJobNameById = id => {
  const classList = computed(() => store.getters.jobs);
  const result = classList.value.find(job => job.id === id);
  return result ? result.name : null;
};
const getDepIdByName = value => {
  const classList = computed(() => store.getters.departments);
  const result = classList.value.find(job => job.label === value);
  return result ? result.value : null;
};
const getDepNameById = id => {
  const classList = computed(() => store.getters.departments);
  const result = classList.value.find(job => job.value === id);
  return result ? result.label : null;
};
const clearObject = object => {
  for (let key in object) {
    delete object[key];
  }
};

/**
 * 将微信模板消息中的内容解析为Map。
 *
 * @param {string} content - 模板消息内容
 * @return {Object} - 解析后的键值对对象
 */
function parseTemplateContent(content) {
  console.log(content);
  // 正则表达式用于匹配键名和描述
  const pattern = /(.*?)：\{\{(\w+)\.DATA\}\}/g;
  let match;
  const resultMap = {};
  resultMap.first = "首要内容";
  while ((match = pattern.exec(content)) !== null) {
    const description = match[1].trim();
    const key = match[2];
    resultMap[key] = description;
    console.log("执行一次", key);
  }
  resultMap.remark = "备注内容";
  return resultMap;
}

/**
 * 生成UUID
 * @return {string}
 */
const guid = () => {
  return "хххххххx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0,
      v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
};

/**
 * 过滤掉文件后缀
 * @param filename
 * @return {*|string}
 */
function extractExtension(filename) {
  // 获取最后一个点的索引
  const index = filename.lastIndexOf('.');
  // 如果存在点，且点不是在第一个位置
  if (index !== -1 && index !== 0) {
    // 返回从点到字符串末尾的部分
    return filename.slice(index);
  }
  // 否则返回空字符串
  return '';
}

/**
 * 判断对象是否为空
 * @param obj
 * @return {boolean}
 */
function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
export const tools = {
  getClassNameById,
  getClassIdByName,
  getJobIdByName,
  getJobNameById,
  getDepIdByName,
  getDepNameById,
  clearObject,
  isEmptyObject,
  guid,
  formatDateNoDay,
  extractExtension,
  parseTemplateContent
};