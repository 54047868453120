// main.ts
import {createApp} from 'vue'
import ElementPlus, {ElDialog} from 'element-plus' //全局引入
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import router from "@/router";
import App from './App.vue'
import store from './vuex/store'
import './common/jacs_base.css'
import print from 'vue3-print-nb'
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'nprogress/nprogress.css'

import wxlogin from 'vue-wxlogin'
import 'vue-awesome/icons'

require('default-passive-events');

const app = createApp(App)


// 遍历导入全部icon
for (const [key,component] of Object.entries(ElementPlusIconsVue)){
    app.component(key,component)
}




ElDialog.props.lockScroll.default = false

app.use(ElementPlus,{
    locale: zhCn,
})

app.use(print)
app.use(router)
app.use(store)
app.component("wxLogin",wxlogin)

app.mount('#app')

export default app


/**权限指令**/
app.directive('has',  (el, binding, vnode) =>{
        // console.log('使用has指令',el)
        // console.log('binding',binding,)
        // console.log('vnode',vnode)
    const route = router.currentRoute;
    const btnPermissionsArr = binding.value;
    // 判断是否展示
    if (!btnPermissionsArr){
        el.parentNode.removeChild(el);
    }
});

