import request from '@/utils/request'
import qs from "qs";

export function getClasses(params){
    return request({
        url:'/classes',
        params,
        method:"GET",
        paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
        },
    })
}

export function dissolutionClasses(id){
    return request({
        url:'/classes',
        params:{
            id
        },
        method:"DELETE"
    })
}

export function saveOrUpdateClasses(data){
    return request({
        url:'/classes',
        data:JSON.stringify(data),
        method:"POST",
        headers: {"Content-Type":"application/json"}
    })
}

export function getClassesByAdvId(id){
    return request({
        url:'/classes/mine-classes',
        params:{id},
        method:"GET"
    })
}

export function importClassesBatch(data){
    return request({
        url:'/classes/batch-import',
        data: JSON.stringify(data),
        method: "POST",
        headers: {"Content-Type":"application/json"},
        timeout: 60000 // 设置更长的超时时间，适用于批量操作
    })
}

export function importClassesAdvisorBatch(data){
    return request({
        url:'/classes/batch-advisor',
        data: JSON.stringify(data),
        method: "POST",
        headers: {"Content-Type":"application/json"},
        timeout: 60000 // 设置更长的超时时间，适用于批量操作
    })
}
