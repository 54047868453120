import Login from '@/views/LoginPage.vue'
import Layout from '@/layout/LayoutIndex.vue'
import CallBack from '@/views/LoginCallback.vue'
import PersonalCenter from '@/views/user/PersonalCenter.vue'

// 基础路由 - 所有用户都可访问的路由
export const constantRoutes = [
  {
    path: '/user',
    component: Layout,
    children: [
        {
          path: '/profile',
          name: 'Profile',
          component: PersonalCenter,
          meta: { 
            title: '个人资料',
            icon: 'flat-color-icons:businessman',
            priority: 3
          }
        }
      ]
  },

  // 认证相关路由 - 不在菜单中显示
  {
    path: '/login', 
    name: 'login', 
    component: Login,
    meta: { title: '登录' }
  },
  {
    path: '/mp/register',
    name: 'mp-register',
    component: () => import("@/views/mp/RegisterPage.vue"),
    meta: { title: '账号注册' }
  },
  {
    path: '/mp/login',
    name: 'LoginPageMp',
    component: () => import("@/views/mp/LoginPage.vue"),
    meta: { title: '登陆账号' }
  },
  {
    path: '/data-screen',
    name: 'DataScreen',
    component: () => import("@/views/others/data-screen/DataSrceen.vue"),
    meta: { 
      title: '德育数据大屏',
      icon: 'flat-color-icons:statistics',
      roles: ['*']
    }
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallBack,
    props: route => ({ code: route.query.code, state: route.query.state })
  },
  {
    path: '/',
    redirect: "/admin"
  },
  {
    path: '/:catchAll(.*)',
    hidden: true,
    component: () => import('@/views/error/ErrorPage404.vue')
  }
] 