import { asyncRoutes } from '@/router/asyncRoutes'
import { constantRoutes } from '@/router/constantRoutes'
import store from '@/vuex/store'
/**
 * 判断用户是否有路由的访问权限
 */
function hasPermission(roles, route) {
  // 如果是 constantRoutes 中的路由且没有设置 roles，则允许访问
  const isConstantRoute = constantRoutes.some(r => r.path === route.path)
  if (isConstantRoute && !route.meta?.roles) {
    return true
  }

  // 检查角色权限
  if (route.meta?.roles) {
    if (route.meta.roles.includes('*')) {
      return true
    }
    return roles.some(role => route.meta.roles.includes(role.name))
  }

  // 检查用户权限
  if (route.meta?.permissions) {
    const permissionList = store.getters.permissions
    return permissionList.some(permission => route.meta.permissions.includes(permission))
  }

// 如果是异步路由且没有设置 roles，默认不允许访问
return true
}

/**
 * 递归过滤异步路由表
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

/**
 * 从路由配置中提取菜单项
 */
function extractMenuItems(routes) {
  const menuItems = []

  routes.forEach(route => {
    if (route.children) {
      route.children.forEach(child => {
        if (child.meta?.group_id) {
          const groupId = child.meta.group_id
          
          // 查找或创建分组
          let group = menuItems.find(item => item.meta?.group_id === groupId)
          if (!group) {
            group = {
              ...child,
              children: []
            }
            menuItems.push(group)
          }

          if (child.children) {
            child.children.forEach(subChild => {
              group.children.push(subChild)
            })
            // 按优先级排序子项
            group.children.sort((a, b) => 
              (a.meta?.priority || 0) - (b.meta?.priority || 0)
            )
          }
        }
      })
    }
  })

  // 按优先级排序分组
  return menuItems.sort((a, b) => 
    (a.meta?.priority || 0) - (b.meta?.priority || 0)
  )
}

const state = {
  routes: [],
  addRoutes: [],
  menuItems: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    // 合并所有路由，但不合并结构
    state.routes = [...constantRoutes, ...routes]
    // 从所有路由中提取菜单项
    state.menuItems = extractMenuItems(state.routes)
  },
  
  RESET_ROUTES: (state) => {
    state.addRoutes = []
    state.routes = [...constantRoutes]
    state.menuItems = extractMenuItems(state.routes)
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes
      if (roles.some(role => role.name === 'admin')) {
        accessedRoutes = asyncRoutes
      } else {
        // 不是管理员，则过滤路由
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
